<template>
  <section
    style="background-color: #FAFAFA; height: 100%"
    id="admin"
    class="pa-0"
  >
    <admin-menu />
    <div
      class="section_division">
      <v-container
        class="view_conatiner">
        <v-slide-y-transition>
          <router-view />
        </v-slide-y-transition>        
      </v-container>
    </div>
  </section>
</template>

<script>
  export default {
    components: {
      AdminMenu: () => import('@/components/sections/Admin/SideMenu')
    },

    data: () => ({
    }),

    computed: {
    }
  }
</script>
<style>
.view_conatiner {
  padding: 6%
}
.section_division {
  margin-left: 15%;
}
.section_title {
  font-family: 'Open sans', sans-serif;
  font-size: 19px;
  color: #000000
}
.content_text {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  color: #000000
}
.table_header_text {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #000000
}
</style>
